.bootstrapTable {
  border: none;

  :global .table {
    thead,
    tbody {
      th,
      td {
        border-right: none;
        border-left: none;
      }
    }

    thead th {
      background: transparent;
    }

    tbody td {
      vertical-align: middle;
    }
  }
}

.image {
  width: 30px;
}

.promoAlert {
  transition: 0.6s;
  transition-timing-function: ease;
  transform: translateX(-130vw);
}

.showAlert {
  transform: translateX(0);
}
