.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.description {
  font-size:12px; 
  font-weight:100;
  color:orange;
}


.image {
  width: 100px;
}

.promoAlert {
  transition: 0.6s;
  transition-timing-function: ease;
  transform: translateX(-130vw);
}

.showAlert {
  transform: translateX(0);
}

.select {
  background: var(--bg-color)! important;
}

.address {
  background: #4f5159; 
}

.text_area {
  min-height: 100px;
  max-height: 100px;
}

.image_container {
  margin-left: 1rem;

  >div:first-child {
    text-align: center;
  }
}