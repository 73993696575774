//@import "../../styles/app";

.root {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  color: white;

  i {
    font-size: 21px;
    margin: 0 5px;//$spacer;
  }
}