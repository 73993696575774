.bootstrapTable {
  border: none;

  :global .table {
    thead,
    tbody {
      th,
      td {
        border-right: none;
        border-left: none;
      }
    }

    thead th {
      background: transparent;
    }

    tbody td {
      vertical-align: middle;
    }
  }
}

.image {
  width: 100px;
}

.promoAlert {
  transition: 0.6s;
  transition-timing-function: ease;
  transform: translateX(-130vw);
}

.showAlert {
  transform: translateX(0);
}

.sub_header {
  background: #4f5159;

  small {
    color: orange;
  }
}

.imageContainer {
  display: flex;
  justify-content: center;

  div:nth-child(2) {
    text-align: center; 
    .image {
      height: 100px;
    }
  }
}