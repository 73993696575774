.card {
    margin-top: 15px;
    width: 100%;
}

.description {
    font-size:12px; 
    font-weight:100;
    color:orange;
}

.select {
    background: var(--bg-color)! important;
}