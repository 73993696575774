
 /*
================================ 
   Page Sign in Styling Code
================================
*/
#page_sign_in {
    background: url('../images/sign_in_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100vh;

    .container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        max-width: 500px;
        height: fit-content;

        h2 {
            font-size: 1.85rem;
            font-weight: 600;
            margin-bottom: 30px;
        }

        form {
            position: relative;
            z-index: 99;
        }

        .logo_box {
            max-width: 300px;
            margin-bottom: 50px;
        
        }

        button.btn {
            height: 60px;
            font-size: 1.6rem;
            font-weight: 500;
            margin-top: 36px;
        }
    }

    .container::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--bg-color);
        z-index: 1;
        opacity: .8;
    }
}