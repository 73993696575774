.bootstrapTable {
  border: none;

  :global .table {
    thead,
    tbody {
      th,
      td {
        border-right: none;
        border-left: none;
      }
    }

    thead th {
      background: transparent;
    }

    tbody td {
      vertical-align: middle;
    }
  }
}

.imageContainer {
  display: flex;
  justify-content: center;

  div:nth-child(2) {
    text-align: center; 
    .image {
      height: 100px;
    }
  }
}

.promoAlert {
  transition: 0.6s;
  transition-timing-function: ease;
  transform: translateX(-130vw);
}

.showAlert {
  transform: translateX(0);
}

.subHeader {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  h3 {
    margin-bottom: 0px !important;
    margin-left: 10px;
  }
}

.select {
  background: var(--bg-color)! important;
}

.textArea {
  min-height: 100px;max-height: 100px;
}

.btnContainer {
  text-align: right;
}

.optionContainer {
  margin-bottom: 40px;
}